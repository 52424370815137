<script setup lang="ts">
const props = defineProps({
  href: {
    type: String,
    default: '',
  },
  blank: {
    type: Boolean,
    default: false,
  },
  /**
   * `true` if `href` points to a static file
   */
  static: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: Boolean,
    default: true
  },
  class: {
    type: String,
    default: ""
  }
})

const linkIcons = {
  "discord.com": "ic:baseline-discord",
  "github.com": "mdi:github"
}

const slots = useSlots()

const isExternal = computed(() => {
  if (!props.href.includes('http://') && !props.href.includes('https://')) return false;
  const url = new URL(props.href)
  return url.hostname !== "pathway.com"
})

const hasIcon = computed(() => {
  if (!isExternal.value) return ""
  const url = new URL(props.href)
  return linkIcons[url.hostname] || ""
})

const hasContent = computed(() => {
  const slotChildren = slots.default?.()
  return slotChildren && typeof slotChildren[0]?.children !== "string"
})
</script>

<template>
  <template v-if="href.includes('/glossary/')">
    <GlossaryLink :url="href" :tooltip="tooltip">
      <slot />
    </GlossaryLink>
  </template>

  <NuxtLink :to="href" :blank="blank" :static="static" :class="class" :target="isExternal ? '_blank' : '_self'" v-else>
    <slot />
    <Icon :name="hasIcon" class="inline text-sm align-middle ml-1" v-if="hasIcon && !hasContent" />
    <Icon name="heroicons:arrow-top-right-on-square-20-solid"
      v-if="isExternal && !hasContent && !hasIcon && (!props.class.includes('button'))"
      class="inline text-sm align-top" />
  </NuxtLink>
</template>

<style lang="postcss" scoped>
:not(h1, h2, h3, h4)>a {
  @apply text-primary-500 hover:border-primary-500 border-b border-transparent no-underline;
}
</style>
